<!--
 * @Author: 张博洋
 * @Date: 2022-03-08 11:22:14
 * @LastEditTime: 2022-03-09 01:15:47
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/patient/editArchives.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <archives @submitForm="submitForm"></archives>
</template>

<script>
import archives from '@/components/archives/modify.vue'
export default {
  components: {
    archives,
  },
  created(){
  },
  methods: {
    submitForm(params) {
      console.log(params)
      this.$axios({
        type: 'post',
        url: 'healthyDoc/submitHealthDocForm',
        data: params,
        elseData: {
          loading: true,
        },
      }).then((res) => {
        console.log(res)
        localStorage.setItem('formId', res.d)
        this.$router.back()
      })
    },
  },
}
</script>
